import * as React from "react";
import Layout from "../../components/layout";
import AnnualReportContent from "../../components/about/annualReportContent";
import heroImage from "../../assets/about/vision-mssion-values-hero.jpg";
import BannerImage from "../../components/master/BannerImage";

const AnnualReportPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="Our Vision, Mission" />
      <AnnualReportContent />
    </Layout>
  );
};

export default AnnualReportPage;
