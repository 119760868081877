import React from "react";
import report2023 from "../../assets/about/2023 StM Annual School Report.pdf";
import report2022 from "../../assets/about/2022 StM Annual School Report.pdf";
import report2021 from "../../assets/about/2021 StM Annual School Report.pdf";
import report2020 from "../../assets/about/2020 StM Annual Report.pdf";
import report2019 from "../../assets/about/2019 StM Annual School Report.pdf";
import PageTitle from "../master/PageTittle";
import ContentWrapper from "../master/ContentWrapper";
import SideBar from "../master/SideBar";
import { about } from "../const";

const AnnualReportContent = () => (
  <div className="container">
    <ContentWrapper sidebar={true} narrow={true}>
      <div className={"content"}>
        <PageTitle title="Annual Report" />

        <p>
          St Maroun’s Annual Report complies with the reporting requirements of
          the NSW Minister for Education and Training and the Commonwealth
          Department of Education, Employment and Workplace Relations.
        </p>

        <p>
          The report contains public disclosure of the educational and financial
          performance measures and policies of the School as required, as well
          as results of our HSC candidates.
        </p>

        <ul className="enrolmentContent">
          <li>
            <a href={report2023}>
              2023 Annual Report – View Annual report 2023
            </a>
          </li>
          <li>
            <a href={report2022}>
              2022 Annual Report – View Annual report 2022
            </a>
          </li>
          <li>
            <a href={report2021}>
              2021 Annual Report – View Annual report 2021
            </a>
          </li>
          <li>
            <a href={report2020}>
              2020 Annual Report – View Annual report 2020
            </a>
          </li>
          <li>
            <a href={report2019}>
              2019 Annual Report – View Annual report 2019
            </a>
          </li>
        </ul>
      </div>
      <SideBar items={about} title="About Us" />
    </ContentWrapper>
  </div>
);

export default AnnualReportContent;
